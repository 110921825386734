.company-project .wrapper {
    padding: 16px;
}

.collection-poster .wrapper {
    background-image: url(http://static.yuanyeapp.com/map-bg.jpg-analysis.detail.img);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh;
}

.project-banner-area {
    border-radius: 8px;
    width: 100%;
    height: 62vh;
    position: relative;
    background-color: #eeeeee;
    background-size: cover;
    background-position: center;
}

.collection-poster .project-banner-area {
    border-radius: 0;
}

.project-banner-area.banner-landscape {
    height: 70vh;
}

.collection-poster .project-banner-area {
    height: 81vh;
}

.company-project-theme-1 .project-banner-area {
    border-radius: 0;
    height: 56vh;
}

.image-corner {
    position: absolute;
    right: 60px;
    bottom: 0;    
}

.image-corner img {
    width: 100px;
}

.company-project .project-title-container {
    left: 24px;
    bottom: 24px;
    top: unset;
    z-index: 100;
    position: absolute;
}

.company-project .project-company-title {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-shadow: 0px 1px 2px #000;
}

.company-project .project-company-title .ant-avatar {
    box-shadow: 0px 0px 0px 1px #fff;
    margin-right: 6px;
}

.company-project .project-title {
    font-size: 40px;
    margin-bottom: 0;
}

.collection-poster .project-title {
    color: #000;
    text-shadow: none;
    font-size: 48px;
    font-weight: 600;
    padding-right: 0;
}

.collection-poster .footer-comment h5 {
    font-size: 16px;
    background-color: #005c0f;
    background-image: url(../../assets/icon-nav.png);
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 16px center;
    border-radius: 100px;
    font-weight: 400;
    display: inline-block;
    margin-bottom: 10px;
    padding: 4px 16px 4px 42px;
    color: #ffffff;
}

.collection-poster .footer-comment .ant-avatar {
    margin-right: 8px;
}

.collection-poster .project-footer-area {
    background-color: rgba(255,255,255,0.75);
    padding-top: 16px;
    backdrop-filter: blur(4px);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 105vw;
    padding-right: 3vw;
}

.footer-comment {
    padding: 32px 60px 8px 40px;
}

.collection-poster .footer-comment {
    padding: 16px 24px;
}

.collection-poster .footer-comment.footer-portrait {
    text-align: center;
}

.footer-project {
    padding: 20px;
}

.footer-project h2 {
    font-size: 30px;
    font-weight: 600;
    color: #738D51;
}

.footer-project h5 {
    font-size: 18px;
    font-weight: 400;
    color: #000;
}

.footer-comment h4 {
    color: #333;
    font-weight: 400;
    margin-bottom: 16px;
}

.footer-comment p {
    color: #000;
    font-weight: 300;
    font-size: 20px;
    border-left: 6px solid #eee;
    padding-left: 12px;
    max-height: 90px;
    overflow: hidden;
}

.no-qrcode {
    visibility: hidden;
}

.footer-qrcode {
    height: 211px;
    text-align: center;
}

.footer-qrcode .ant-avatar {
    margin: 24px 10px 10px;
}

.footer-qrcode-hint {
    font-size: 16px;
    font-weight: 300;
    color: 000;
}