
.project-banner {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: #eeeeee;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.project-title-area {
    width: 100%;
    height: 100vh;
    position: relative;
}

.project-title-container {
  position: fixed;
  left: 36px;
  top: 80px;
}

.project-title {
  color: #fff;
  font-size: 56px;
  font-weight: 600;
  text-shadow: 0px 1px 2px #000;
  padding-right: 36px;
  margin-bottom: 16px;
}

.project-company {
    display: inline-flex;
    align-items: center;
    padding: 8px 12px 8px 8px;
    background-color: #fff;
    border-radius: 100px;
    box-shadow: 0px 0px 4px #666;
}

.project-company h4 {
    margin-bottom: 1px;
    margin-left: 8px;
    font-size: 16px;
    font-weight: 600;
}

.project-ext-area {
    padding: 36px;
    min-height: 221px;
    position: relative;
    background-color: #fff;
}

.project-qrcode {
    position: fixed;
    bottom: 0px;
    right: 48px;
    padding: 10px;
    height: 211px;
    background-color: #fff;
    border-radius: 200px 200px 0 0;
    z-index: 100;
}

.qrcode-hint {
    position: absolute;
    right: 0px;
    bottom: 12px;
    font-size: 16px;
    font-weight: 300;
    color: #000;
    background-color: #fff;
    width: 149px;
    text-align: center;
}

@media screen and (max-width: 767px) {
    .project-banner {
      background-attachment: inherit;
    }
  }
