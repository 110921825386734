.mobile-wrapper {
    width: 550px;
    margin: 0px auto;
    box-shadow: 0px 0px 40px 20px rgba(0,0,0,0.1);
}

.wrapper p, .wrapper-p {
    margin: 8px 32px 60px;
    line-height: 1.8;
    font-size: 14px;
    color: #333;
}

.full-width {
    width: 100%;
    height: 100%;
}

.block {
    background-color: #1C85F0;
    color: #fff;
    padding: 24px 0;
    line-height: 2;
}

.large-title {
    font-weight: 600;
    font-size: 20px;
    color: #1C85F0;
    margin: 40px 32px 16px;
    line-height: 1.5;
    text-align: center;
}

.pro {
    background-color: #1C85F0;
    color: #fff;
    font-weight: 600;
    font-size: 10px;
    border-radius: 2px;
    padding: 1px 3px;
    margin: 0 6px 0 3px;
    line-height: 12px;
    display: inline-block;
}

.block .pro {
    background-color: #fff;
    color: #1C85F0;
}

.large-title .ant-avatar {
    margin-right: 5px;
}

.logo-title {
    color: #000;
    background-color: #f7f7f7;
    display: flex;
    align-items: top;
    margin: 20px 24px;
    padding: 24px 20px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
}

.logo-title .subtitle {
    display: flex;
    align-items: center;
    color: #666;
    font-size: 14px;
    line-height: 1.4;
    font-weight: 400;
}

.logo-title .ant-avatar {
    margin-right: 8px;
}

.block-title {
    color: #1C85F0;
    display: flex;
    align-items: center;
    margin: 40px 40px 8px;
    font-size: 16px;
    font-weight: 600;
}

.mobile-wrapper .block .block-title {
    font-size: 20px;
}

.center-title {
    color: #000000;
    text-align: center;
    padding: 64px 32px 8px;
    font-size: 22px;
    line-height: 1.5;
    font-weight: 600;
}

.mobile-wrapper .center-title {
    font-size: 26px;
}

.center-title .subtitle {
    display: block;
    font-weight: 400;
    font-size: 16px;
    margin-top: 4px;
    line-height: 1.6;
    color: #666;
}

.mobile-wrapper .center-title .subtitle {
    font-size: 20px;
}

.black-bg {
    background-color: #000000;
    color: #fff;
}

.black-bg .subtitle {
    color: #bbb;
}

.grey-bg {
    background-color: #f7f7f7;
}

.action-img {
    position: relative;
}

.action-title {
    position: absolute;
    padding: 0;
}

@keyframes blink{0%{opacity:1}50%{opacity:0}to{opacity:1}}

.healthy-dot {
    position: absolute;
    left: 50%;
    margin-left: -83px;
    top: 50px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
}

.mobile-wrapper .healthy-dot {
    top: 80px;
    font-size: 16px;
}

.healthy-dot span {
    display: block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border-radius: 30px;
    background-color: #07c160;
    animation: ease 2s blink infinite;
}

.action-img .ant-anchor-wrapper {
    position: absolute;
    background-color: transparent;
}

.action-img.action-right-center .ant-anchor-wrapper {
    right: 30px;
    top: 50%;
    margin-top: -14px;
}

.action-img.action-right-center-2 .ant-anchor-wrapper {
    right: 48px;
    top: 132px;
}

.mobile-wrapper .action-img.action-right-center .ant-anchor-wrapper {
    right: 50px;
}

.action-img.action-right-top .ant-anchor-wrapper {
    right: 60px;
    top: 60px;
}

.mobile-wrapper .action-img.action-right-top .ant-anchor-wrapper {
    top: 100px;
    right: 100px;
}

.action-img.action-right-bottom .ant-anchor-wrapper {
    right: 45px;
    bottom: 35px;
}

.action-img.action-center-bottom .ant-anchor-wrapper {
    bottom: 12px;
    left: 50%;
    margin-left: -50px;
}

.mobile-wrapper .action-img.action-center-bottom .ant-anchor-wrapper {
    margin-left: -60px;
}

.action-img.action-left-center .ant-anchor-wrapper {
    left: 16px;
    top: 50%;
    margin-top: -14px;
}

.action-img.action-left-center .action-title {
    left: 48px;
    top: 60px;
    text-align: left;
}

.action-img.action-right-center-2 .action-title {
    right: 48px;
    top: 60px;
    text-align: right;
}

.action-img.action-center-center .action-title {
    left: 50%;
    top: 50%;
    margin-left: -66px;
    margin-top: -60px;
    text-align: center;
}

.action-img.action-center-center .ant-anchor-wrapper {
    left: 50%;
    top: 50%;
    margin-left: -46px;
    margin-top: -15px;
}

.ant-anchor-ink::before {
    display: none;
}

.action-btn {
    color: #FFF;
    background-color: #1C85F0;
    font-size: 15px;
    line-height: 1.8;
    font-weight: 600;
    border-radius: 100px;
}

.mobile-wrapper .action-btn {
    font-size: 17px;
}

.action-btn.ant-anchor-link {
    padding: 2px 16px;
}

.mobile-wrapper .action-btn.ant-anchor-link {
    padding: 4px 24px;
}

.action-btn .ant-anchor-link-title {
    color: #FFF;
}

.block .large-title {
    color: #fff;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5;
}

.block .block-title {
    color: #fff;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.8;
    font-size: 18px;
}

.block-title .ant-avatar {
    margin-right: 5px;
    font-size: 16px;
}

.mobile-wrapper .ant-collapse-header {
    font-size: 16px;
}

.ul {
    line-height: 2.2;
    color: #000;
    font-weight: 400;
    font-size: 14px;
    list-style: none;
    padding-left: 24px;
}

.mobile-wrapper .ul {
    font-size: 16px;
}

.ul li {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.ul li:last-child {
    border-bottom: none;
}

.ol {
    margin-bottom: 0;
}

.mobile-wrapper .ol {
    font-size: 16px;
}

.small-block {
    padding: 40px 40px;
    text-align: center;
}

.mobile-wrapper .small-block {
    font-size: 16px;
}

.wrapper .ant-result {
    padding: 48px 0;
}

.block-img {
    width: 100vw;
}

.mobile-wrapper .block-img {
    width: 100%;
}

.ant-carousel {
    margin: 24px 0;
}

.ant-carousel .slick-dots li button {
    background: #ccc;
}

.ant-carousel .slick-dots li.slick-active button {
    background: #999;
}

.testimony {
    padding: 12px 36px 24px;
}

.testimony h3 {
    position: relative;
    color: #000;
    text-indent: 2em;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 16px;
}

.mobile-wrapper .testimony h3 {
    font-size: 18px;
}

.testimony h5 {
    font-weight: 300;
    color: #000;
    font-size: 13px;
    text-align: right;
    margin-bottom: 24px;
}

.mobile-wrapper .testimony h5 {
    font-size: 15px;
}

.testimony h3::before {
    content: '“';
    position: absolute;
    left: -36px;
    top: -24px;
    font-size: 48px;
    color: #1C85F0;
}

