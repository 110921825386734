.company-127 .cover-bg {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: #eeeeee;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 50px;
}

.company-127.portrait .cover-bg {
    padding-left: 0;
}

.company-127 .poster-mask {
    background-image: url(../../assets/127-poster-mask-landscape.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 590px;
    height: 710px;
    position: relative;
}

.company-127.portrait .poster-mask {
    background-image: url(../../assets/127-poster-mask-portrait.png);
    height: 856px;
    margin: 0 auto;
}

.company-127 .project-content {
    margin: 24px;
}

.company-127 .project-title {
    color: #3c3c3c;
    font-size: 42px;
    font-weight: 600;
    text-shadow: none;
    padding: 0;
}

.company-127 .project-title.small-font {
    font-size: 34px;
}

.company-127 .project-summary {
    color: #666666;
    font-size: 26px;
    font-weight: 300;
    text-shadow: none;
    margin-right: 256px;
    line-height: 1.6;
}

.company-127 .poster-qrcode {
    text-align: center;
    position: absolute;
    left: 474px;
    bottom: 20px;
}

.company-127 .poster-qrcode .qrcode {
    width: 86px;
    height: 86px;
    border-radius: 10%;
    display: block;
    margin-bottom: 6px;
}

.company-127 .poster-qrcode-hint {
    font-size: 12px;
    font-weight: 400;
    color: #000;
}