.company-card {
    text-align: center;
}

.company-card .ant-card-cover {
    padding-top: 24px;
}

.company-card .ant-card-cover > * {
    display: inline-block;
}

.logo-area {
    margin: 24px 24px;
}

.company-list-area {
    margin: 24px;
    
}

.company-list {
    border-top: 1px solid #eee;
    padding-top: 24px;
}