.exception-hint {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-top: 200px;
}

.exception-btn {
    margin-top: 24px;
    text-align: center;
}