.btn-icon {
    width: 60px;
    height: 60px;
    display: block;
}

.icon-phone {
    -webkit-mask: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M32.998 26.692c0 .384-.07.884-.213 1.502-.142.618-.291 1.104-.448 1.46-.298.71-1.165 1.462-2.599 2.258-1.335.724-2.656 1.086-3.963 1.086-.383 0-.756-.025-1.118-.074a9.235 9.235 0 01-1.225-.267 17.5 17.5 0 01-1.012-.308c-.22-.078-.614-.224-1.182-.437a43.959 43.959 0 00-1.045-.383c-1.392-.498-2.635-1.087-3.729-1.769-1.818-1.122-3.696-2.652-5.635-4.59-1.939-1.94-3.469-3.818-4.59-5.636-.683-1.094-1.272-2.337-1.77-3.729-.042-.128-.17-.476-.382-1.044a81.424 81.424 0 01-.437-1.183 17.5 17.5 0 01-.309-1.012 9.235 9.235 0 01-.266-1.225A8.195 8.195 0 013 10.223c0-1.307.362-2.627 1.087-3.963.795-1.434 1.548-2.3 2.258-2.599.355-.156.841-.306 1.46-.448C8.421 3.071 8.922 3 9.304 3c.2 0 .349.021.449.064.255.085.632.625 1.13 1.62.156.269.369.652.639 1.15.27.496.518.947.746 1.352.227.405.447.785.66 1.14.042.057.167.234.373.533.206.298.359.55.458.756.1.206.15.408.15.607 0 .284-.203.64-.607 1.065-.405.426-.845.817-1.321 1.172a12.8 12.8 0 00-1.322 1.13c-.404.397-.606.724-.606.98 0 .127.035.287.106.479s.132.337.182.437c.05.099.149.27.298.511.149.242.23.377.245.405 1.08 1.946 2.315 3.615 3.707 5.007 1.392 1.392 3.061 2.628 5.007 3.707.029.015.164.096.405.245.241.15.412.249.511.298.1.05.246.11.437.182.192.07.351.106.48.106.255 0 .582-.202.98-.606a12.8 12.8 0 001.13-1.322c.354-.476.745-.916 1.17-1.32.427-.405.782-.608 1.066-.608.199 0 .4.05.607.15.205.1.458.252.756.458.299.206.476.33.533.373.355.213.735.433 1.14.66.404.227.855.476 1.353.746.497.27.88.484 1.15.64.994.497 1.534.874 1.62 1.13.042.099.063.248.063.447l-.002-.002z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
}

.icon-email {
    -webkit-mask: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.09 11.374V7.909H4.91v3.465L18 17.919l13.09-6.545zm0 3.254L18 21.173 4.91 14.628v13.645h26.18V14.628zM4.91 5h26.18A2.91 2.91 0 0134 7.91v20.363a2.91 2.91 0 01-2.91 2.909H4.91A2.91 2.91 0 012 28.272V7.91A2.91 2.91 0 014.91 5z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
}


.card-wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;
    text-align: right;
    height: 80vw;
    color: #000;
}

.card-mask {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: bottom -32px left;
}

.card-qrcode {
    position: absolute;
    left: 0;
    bottom: 136px;
    background-color: #ffffff;
    width: 92px;
    height: 86px;
    padding: 8px;
    box-sizing: content-box;
    border-radius: 0 200px 200px 0;
    opacity: 1;
    visibility: visible;
}

.card-qrcode .qrcode {
    width: 86px;
    height: 86px;
}

.content-content {
    max-height: 336px;
    overflow: hidden;
}

.card-name {
    font-size: 54px;
    font-weight: 600;
    letter-spacing: 4px;
    padding: 54px 44px 4px;
    position: relative;
}

.card-title {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.4;
    padding: 0px 48px;
    position: relative;
}

.card-title-alt {
    font-size: 26px;
    font-weight: 300 !important;
    position: relative;
    padding: 0px 48px;
}

.card-title pre, .card-title-alt pre {
    margin: 0 !important;
    padding: 0 !important;
}

.card-title pre {
    font-weight: 500 !important;
}

.card-title-alt pre {
    font-weight: 300 !important;
}

.card-card {
    position: absolute;
    right: 48px;
    bottom: 32px;
    padding-left: 48px;
}

.card-company {
    font-size: 24px;
    font-weight: 600;
}

.card-other {
    font-size: 22px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 1.8;
}

.card-other .btn-icon {
    width: 32px;
    height: 32px;
    margin-left: 26px;
    margin-right: 6px;
    background-color: #000;
}